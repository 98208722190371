body {
    font-family: 'DM Sans';
    margin: 0px;
}

a {
    transition: 0.3s ease-in-out;
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

.welcome-h1 {
    font-family: 'DM Sans';
    color: #001B23;
    font-size: 42px;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 0.01em;
    text-align: center;
    margin: 0px;
}

.welcome-h5 {
    font-family: 'DM Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #001B23;
    margin: 0px 0px 8px 0px;
}

.wrapper {
    background-image: url(../images/bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.header-logo {
    padding: 60px 20px;
    text-align: center;
}

.welcome-title-sub {
    margin: 0px auto 80px;
    max-width: 800px;
    width: 100%;
    padding: 0px 20px;
    text-align: center;
    gap: 24px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.welcome-ttl-sb {
    font-family: 'DM Sans';
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #5B5B5B;
    max-width: 580px;
    margin: 0px auto;
}

.welcome-box-main {
    max-width: 1240px;
    width: 100%;
    margin: 0px auto 60px;
    padding: 0px 20px;
    display: flex;
    gap: 24px;
    box-sizing: border-box;
    justify-content: center;
}
.peak-welcome-box-main {
    max-width: 1480px !important;
    width: 100%;
    margin: 0px auto 60px;
    padding: 0px 20px;
    display: flex;
    gap: 24px;
    box-sizing: border-box;
    justify-content: center;
}

.welcome-box-main a {
    text-decoration: inherit;
    width: 100%;
}

.welcome-box-main-in {
    background: #FFF;
    border-radius: 16px;
    box-shadow: 0px 4px 32px 0px #0000000D;
    padding: 24px 16px;
}

.welcome-box-main-in:hover {
    box-shadow: 0px 4px 32px 0px #00000018;
}

.welcome-box-icon {
    margin-bottom: 24px;
    display: block;
}

.welcome-box-p {
    margin: 0px;
    font-family: 'DM Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5B5B5B;
}


@media only screen and (max-width: 1200px) {
    .wrapper {
        height: inherit;
    }
}

@media only screen and (max-width: 999px) {
    .welcome-box-main {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 767px) {
    .welcome-box-main-in {
        text-align: center;
    }

    .welcome-box-main {
        flex-direction: column;
        margin: 0px auto 60px;
    }

    .welcome-h1 {
        font-size: 24px;
        line-height: 36px;
    }

    .header-logo {
        padding: 60px 20px;
        text-align: center;
    }

    .welcome-title-sub {
        margin: 0px auto 60px;
        gap: 12px;
    }

    .welcome-title-sub {
        margin: 0px auto 60px;
        gap: 12px;
    }
}


@media only screen and (max-height: 800px) {
    .wrapper {
        height: inherit;
    }
}




/* CSS FOR THE COVER PAGE ICONS */

.svg-primary{
    fill: var(--primary-color);
}
.svg-secondary{
    fill: var(--secondary-color);
}

.svg-tertery{
    fill:var(--primary-button-bg);  
}

.img-logo {
    height: 100px;
}

.eastonpreferredpark .img-logo {
    height: 50px;
}

